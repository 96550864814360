import SvgIcon from "../../../designSystem/SvgIcon";

const CzechFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 640 480'>
        <rect width='640' height='240' fill='#ffffff' />

        <rect width='640' height='240' y='240' fill='#d7141a' />

        <polygon points='0,0 0,480 320,240' fill='#11457e' />
      </svg>
    </SvgIcon>
  );
};

export default CzechFlagIcon;
