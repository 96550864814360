import SvgIcon from "../../../designSystem/SvgIcon";

const UKFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='2500'
        height='2500'
        viewBox='0 0 192.756 192.756'
      >
        <path fill-rule='evenodd' clip-rule='evenodd' fill='#fff' d='M0 0h192.756v192.756H0V0z' />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#fff'
          d='M184.252 149.596V43.161H8.504v106.435h175.748z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#cc2229'
          d='M184.252 101.861V91.414H8.504v10.447h175.748z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#1e5593'
          d='M87.538 88.495V43.161H8.504v45.334h79.034z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#fff'
          d='M60.595 88.495h26.943v-5.328L20.954 43.161H8.504v13.852l52.091 31.482z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#cc2229'
          d='M8.504 53.139v-9.978l74.329 45.334h-15.47L8.504 53.139z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#1e5593'
          d='M87.538 104.455v45.141H8.504v-45.141h79.034z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#fff'
          d='M60.844 104.455h26.694v6.008l-57.944 39.133H8.504v-10.174l52.34-34.967z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#cc2229'
          d='M22.056 149.596l65.482-45.141H75.943L8.504 149.596h13.552z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#1e5593'
          d='M105.219 88.495V43.161h79.033v45.334h-79.033z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#fff'
          d='M132.162 88.495h-26.943v-5.328l66.584-40.006h12.449v13.852l-52.09 31.482z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#cc2229'
          d='M184.252 53.139v-9.978l-74.328 45.334h15.469l58.859-35.356z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#1e5593'
          d='M105.219 104.455v45.141h79.033v-45.141h-79.033z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#fff'
          d='M131.912 104.455h-26.693v6.008l57.943 39.133h21.09v-10.174l-52.34-34.967z'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          fill='#cc2229'
          d='M170.701 149.596l-65.482-45.141h11.593l67.44 45.141h-13.551zM101.654 149.596V43.161H91.163v106.435h10.491z'
        />
        <path
          fill='none'
          stroke='#000'
          stroke-width='.499'
          stroke-miterlimit='2.613'
          d='M184.252 149.596V43.161H8.504v106.435h175.748z'
        />
      </svg>
    </SvgIcon>
  );
};

export default UKFlagIcon;
