import { keyMirror } from "../../utils/common";

export const Entities = keyMirror(
  "DASHBOARD",
  "CONTENT_LIBRARY",
  "POST",
  "CONTENT_CALENDAR",
  "IDEA_LAB",
  "COMPETITOR_ANALYSIS",
  "HELP",
  "MANAGE_BRAND",
  "ACCOUNT",
  "LANGUAGE_SELECTION",
  "TRAINING_VIDEO",
  "PRODUCT_ROADMAP",
  "USER",
  "FIVERR",
  "WHITELABEL",
  "PREDIS_BETA"
);
// negative permissions - don't need whitelabel in main app
const removeFromAll = ["WHITELABEL"];
const mainAppEntities = keyMirror(
  ...Object.keys(Entities).filter((i) => !removeFromAll.includes(i))
);

export const Sections = {
  [Entities.MANAGE_BRAND]: keyMirror(
    "BRAND_DETAILS",
    "SOCIAL_PLATFORM",
    "INTEGRATION",
    "UPLOADS",
    "TEMPLATES",
    "EXPORTS"
  ),
  [Entities.USER]: keyMirror("LOGOUT")
};

export const Operations = keyMirror("ALL", "CREATE", "READ", "UPDATE", "DELETE", "EXECUTE");

export const all_permissions = Object.keys(mainAppEntities).map((_entity) => ({
  entity: _entity,
  sections: Sections[_entity]
    ? Object.keys(Sections[_entity]).map((_section) => ({
        section: _section,
        operations: [
          { operation: Operations.CREATE },
          { operation: Operations.READ },
          { operation: Operations.UPDATE },
          { operation: Operations.DELETE }
        ]
      }))
    : [],
  operations: [
    { operation: Operations.CREATE },
    { operation: Operations.READ },
    { operation: Operations.UPDATE },
    { operation: Operations.DELETE }
  ]
}));

export const detailed_mode = [
  {
    entity: Entities.IDEA_LAB,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.CONTENT_LIBRARY,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.POST,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.COMPETITOR_ANALYSIS,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.HELP,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.MANAGE_BRAND,
    sections: [
      {
        section: Sections[Entities.MANAGE_BRAND].BRAND_DETAILS,
        operations: [
          { operation: Operations.CREATE },
          { operation: Operations.READ },
          { operation: Operations.UPDATE },
          { operation: Operations.DELETE }
        ]
      },
      {
        section: Sections[Entities.MANAGE_BRAND].UPLOADS,
        operations: [
          { operation: Operations.CREATE },
          { operation: Operations.READ },
          { operation: Operations.UPDATE },
          { operation: Operations.DELETE }
        ]
      },
      {
        section: Sections[Entities.MANAGE_BRAND].TEMPLATES,
        operations: [
          { operation: Operations.CREATE },
          { operation: Operations.READ },
          { operation: Operations.UPDATE },
          { operation: Operations.DELETE }
        ]
      },
      {
        section: Sections[Entities.MANAGE_BRAND].EXPORTS,
        operations: [
          { operation: Operations.CREATE },
          { operation: Operations.READ },
          { operation: Operations.UPDATE },
          { operation: Operations.DELETE }
        ]
      }
    ],
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.ACCOUNT,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.LANGUAGE_SELECTION,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.TRAINING_VIDEO,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.PRODUCT_ROADMAP,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  }
];

export const simple_mode = [
  {
    entity: Entities.POST,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  },
  {
    entity: Entities.CONTENT_LIBRARY,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  }
];

export const detailed_mode_wl = [
  ...detailed_mode,
  {
    entity: Entities.WHITELABEL,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  }
];

export const simple_mode_wl = [
  ...simple_mode,
  {
    entity: Entities.WHITELABEL,
    operations: [
      { operation: Operations.CREATE },
      { operation: Operations.READ },
      { operation: Operations.UPDATE },
      { operation: Operations.DELETE }
    ]
  }
];
