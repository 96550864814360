import SvgIcon from "../../../designSystem/SvgIcon";

const NorwayFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={2500}
        height={1812}
        viewBox='0 0 217.653 157.75'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#cc2229'
          d='M.827.827h215.999v156.096H.827V.827z'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#fff'
          stroke='#fff'
          strokeWidth={0.118}
          strokeMiterlimit={2.613}
          d='M216.756 59.795v38.52H98.675v58.321h-38.52V98.315H1.115v-38.52h59.04V1.115h38.52v58.68h118.081z'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#2c6698'
          d='M.899 88.595V69.371h68.904V.971h19.296v68.4h127.512v19.224H89.099v68.257H69.803V88.595H.899z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={1.654}
          strokeMiterlimit={2.613}
          d='M.827.827h215.999v156.096H.827V.827z'
        />
      </svg>
    </SvgIcon>
  );
};

export default NorwayFlagIcon;
