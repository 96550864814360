import SvgIcon from "../../../designSystem/SvgIcon";

const RomaniaFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={2500}
        height={1643}
        viewBox='0 0 216 141.984'
        {...props}
      >
        <g fillRule='evenodd' clipRule='evenodd'>
          <path fill='#fff22d' d='M0 0h215.928v141.984H0V0z' />
          <path fill='#33348e' d='M0 0h71.928v141.984H0V0z' />
          <path fill='#cc2229' d='M143.855 0H216v141.984h-72.145V0z' />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default RomaniaFlagIcon;
