import SvgIcon from "../../../designSystem/SvgIcon";

const SwedenFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={2500}
        height={1570}
        viewBox='0 0 217.079 136.296'
        {...props}
      >
        <g
          fillRule='evenodd'
          clipRule='evenodd'
          stroke='#000'
          strokeWidth={1.08}
          strokeLinejoin='bevel'
          strokeMiterlimit={2.613}
        >
          <path fill='#3171b0' d='M.54.54h215.999v135.216H.54V.54z' />
          <path
            fill='#fff237'
            d='M94.572 135.756H65.196V80.748H.54V52.164h64.656V.54h29.376v51.624h121.967v28.584H94.572v55.008z'
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default SwedenFlagIcon;
