import SvgIcon from "../../../designSystem/SvgIcon";

const DenmarkFlag2Icon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={2500}
        height={1589}
        viewBox='0 0 217.047 137.99'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#cc2229'
          d='M.524.524h215.999v136.943H.524V.524z'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#fff'
          d='M56.396.524v59.472H.524v18.719h55.872v58.752h22.968V78.715h137.159V60.068H79.364V.524H56.396z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={1.047}
          strokeMiterlimit={2.613}
          d='M.524.524h215.999v136.943H.524V.524z'
        />
      </svg>
    </SvgIcon>
  );
};

export default DenmarkFlag2Icon;
