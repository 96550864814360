import SvgIcon from "../../../designSystem/SvgIcon";

const IndonesiaFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={2500}
        height={1669}
        viewBox='0 0 217.079 144.937'
        {...props}
      >
        <g
          fillRule='evenodd'
          clipRule='evenodd'
          stroke='#000'
          strokeWidth={1.08}
          strokeLinejoin='bevel'
          strokeMiterlimit={2.613}
        >
          <path fill='#fff' d='M.54.54h215.999v143.856H.54V.54z' />
          <path fill='#cc2229' d='M.54.54h215.999v71.928H.54V.54z' />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default IndonesiaFlagIcon;
