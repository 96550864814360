import SvgIcon from "../../../designSystem/SvgIcon";

const PolandFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={2500}
        height={1486}
        viewBox='0 0 216.833 128.849'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#fff'
          d='M216.345 128.36H.488V.488h215.857V128.36z'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#cc2229'
          d='M216.416 128.36H.417V63.848h215.999v64.512z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeMiterlimit={2.613}
          d='M216.345 128.36H.488V.488h215.857V128.36z'
        />
      </svg>
    </SvgIcon>
  );
};

export default PolandFlagIcon;
