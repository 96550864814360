import { Suspense } from "react";
import Box from "./designSystem/Box";
import styles from "./loader.module.css";

export const LazyLoader = ({ children, fallback = null }) => {
  return (
    <Suspense
      fallback={
        fallback ? (
          fallback
        ) : (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center"
            }}
          >
            <Box sx={{ width: "300px" }}>
              <span className={styles.loader}></span>
            </Box>
            Loading...
          </Box>
        )
      }
    >
      {children}
    </Suspense>
  );
};
