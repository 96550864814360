import SvgIcon from "../../../designSystem/SvgIcon";

const ChineseFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        id='\u56FE\u5C42_1'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 300 200'
        width={2500}
        height={1667}
        {...props}
      >
        <style>{".st0{fill:#d71718}.st1{fill:#f5e929}"}</style>
        <path transform='rotate(-90 150 100)' className='st0' d='M50-50h200v300H50z' />
        <path
          className='st1'
          d='M50 30l4.5 13.8H69l-11.7 8.6 4.5 13.8L50 57.6l-11.7 8.6 4.4-13.8L31 43.8h14.5zM103.6 80.7l-.4 7.2 6.8 2.7-7 1.8-.5 7.3-3.9-6.2-7 1.9 4.6-5.6-3.9-6.1 6.7 2.6zM92.5 13.4l6.6 2.9 4.8-5.5-.6 7.2 6.7 2.9-7.1 1.6-.6 7.2-3.8-6.2-7.1 1.6 4.8-5.4zM115.7 31l5 5.2 6.6-3.1-3.5 6.4 5 5.3-7.2-1.4-3.4 6.4-1-7.2-7.1-1.3 6.6-3.1zM119.3 60l2.7 6.8 7.3-.5-5.6 4.6 2.7 6.8-6.1-3.9-5.6 4.7 1.8-7.1-6.2-3.8 7.3-.5z'
        />
      </svg>
    </SvgIcon>
  );
};

export default ChineseFlagIcon;
