import SvgIcon from "../../../designSystem/SvgIcon";

const FinlandFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={1800}
        height={1100}
        viewBox='0 0 18 11'
        {...props}
      >
        <rect width={18} height={11} fill='#fff' />
        <path d='M0,5.5h18M6.5,0v11' stroke='#002F6C' strokeWidth={3} />
      </svg>
    </SvgIcon>
  );
};

export default FinlandFlagIcon;
