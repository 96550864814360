import SvgIcon from "../../../designSystem/SvgIcon";

const HungaryFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={2500}
        height={1503}
        viewBox='0 0 216.988 130.444'
        {...props}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#fff'
          d='M.494.494h216V129.95h-216V.494z'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#cc2229'
          d='M.494.494h216v43.344h-216V.494z'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          fill='#378950'
          d='M.494 87.326h216v42.552h-216V87.326z'
        />
        <path
          fill='none'
          stroke='#000'
          strokeWidth={0.988}
          strokeMiterlimit={2.613}
          d='M.494.494h216V129.95h-216V.494z'
        />
      </svg>
    </SvgIcon>
  );
};

export default HungaryFlagIcon;
