import SvgIcon from "../../../designSystem/SvgIcon";

const TurkeyFlagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={2500}
        height={1572}
        viewBox='0 0 216 135.792'
        {...props}
      >
        <g fillRule='evenodd' clipRule='evenodd'>
          <path fill='#cc2229' d='M0 0h216v135.792H0V0z' />
          <path
            d='M80.424 93.096c-4.752 2.664-8.712 3.744-14.112 3.744-15.912 0-29.088-13.104-29.088-28.944 0-15.984 13.176-29.088 29.088-29.088 5.4 0 9.36 1.08 14.112 3.744-6.264-6.264-15.192-10.296-24.912-10.296-19.584 0-35.64 16.056-35.64 35.64 0 19.44 16.056 35.496 35.64 35.496 9.72 0 18.648-3.96 24.912-10.296zm18-20.592l10.224 3.384-7.056-8.064 6.624-8.496-10.225 3.744-6.408-9 .72 10.8-10.296 3.743 10.656 2.16.216 10.801 5.545-9.072z'
            fill='#fff'
            stroke='#fff'
            strokeWidth={0.111}
            strokeMiterlimit={2.613}
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default TurkeyFlagIcon;
